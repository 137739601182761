/* ========================================================= * Material Kit 2 PRO React - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/material-kit-pro-react * Copyright 2021 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

// next
import dynamic from 'next/dynamic';
import Link from 'next/link';

// routes
import footerRoutes from 'routes/footer';

// MUU
const Container = dynamic(() => import('@mui/material/Container'), { loading: () => null });
const Grid = dynamic(() => import('@mui/material/Grid'), { loading: () => null });

// PRO
const MDBox = dynamic(() => import('components/MDBox'), { loading: () => null });
const MDTypography = dynamic(() => import('components/MDTypography'), { loading: () => null });

function DefaultFooter() {
  const {
    brand, socials, menus, copyright,
  } = footerRoutes;

  return (
    <MDBox component="footer">
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} sx={{ ml: 'auto', mb: 3 }}>
            <MDBox>
              {/*
              <Link href={brand.route}>
                <Image src={brand.image} alt={brand.name}
                maxWidth="2rem" width={40} height={40} mb={2} />
              </Link>
              */}
              <MDTypography variant="h6" component="h2" aria-label={brand.name}>{brand.name}</MDTypography>
            </MDBox>
            <MDBox display="flex" alignItems="center" mt={3}>
              {socials.map(({ icon, link }, key) => (
                <MDTypography
                  aria-label="Github profile"
                  key={link}
                  component="a"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  variant="h5"
                  color="dark"
                  opacity={0.8}
                  mr={key === socials.length - 1 ? 0 : 2.5}
                >
                  {icon}
                </MDTypography>
              ))}
            </MDBox>
          </Grid>
          {menus.map(({ name: title, items }) => (
            <Grid key={title} item xs={6} md={2} sx={{ mb: 3 }}>
              <MDTypography
                display="block"
                variant="button"
                fontWeight="bold"
                textTransform="capitalize"
                mb={1}
              >
                {title}
              </MDTypography>
              <MDBox component="ul" p={0} m={0} sx={{ listStyle: 'none' }}>
                {items.map(({ name, route, href }) => (
                  <MDBox key={name} component="li" p={0} m={0} lineHeight={1.25}>
                    {href ? (
                      <MDTypography
                        component="a"
                        href={href}
                        target="_blank"
                        rel="noreferrer"
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                      >
                        {name}
                      </MDTypography>
                    ) : (
                      <Link href={route} scroll={route === '/'}>
                        <MDTypography
                          variant="button"
                          fontWeight="regular"
                          textTransform="capitalize"
                        >
                          {name}
                        </MDTypography>
                      </Link>
                    )}
                  </MDBox>
                ))}
              </MDBox>
            </Grid>
          ))}
          <Grid item xs={12} sx={{ textAlign: 'center', my: 3 }}>
            {copyright}
          </Grid>
        </Grid>
      </Container>
    </MDBox>
  );
}

// Typechecking props for the DefaultFooter
DefaultFooter.propTypes = {};

export default DefaultFooter;
