/* eslint-disable import/no-anonymous-default-export */

// next
import Link from 'next/link';

// @mui icons
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
// import YouTubeIcon from '@mui/icons-material/YouTube';

// PRO
import MDTypography from 'components/MDTypography';

// images
import logoCT from 'assets/img/logo-ct-dark.png';

const date = new Date().getFullYear();

export default {
  brand: {
    name: 'CodeFox',
    image: logoCT,
    route: '/',
  },
  socials: [
    {
      icon: <GitHubIcon />,
      link: 'https://github.com/codefox1',
    },
  ],
  menus: [
    {
      name: 'about',
      items: [
        { name: 'home', route: '/' },
        { name: 'skills', route: '/#skills' },
      ],
    },
    {
      name: 'work',
      items: [
        { name: 'my work', route: '/#my-work' },
        { name: 'reviews', route: '/#reviews' },
      ],
    },
    {
      name: 'Contact',
      items: [
        { name: 'send message', route: '/#contact' },
      ],
    },
    {
      name: 'legal',
      items: [
        { name: 'privacy', route: '/privacy' },
        { name: 'terms', route: '/terms' },
      ],
    },
  ],
  copyright: (
    <MDTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy;
      {' '}
      {date}
      {' '}
      <Link href="/">
        <MDTypography
          variant="button"
          fontWeight="regular"
          sx={{ cursor: 'pointer' }}
        >
          CodeFox
        </MDTypography>
      </Link>
      .
    </MDTypography>
  ),
};
